import React from "react";
import "./noti.scss";

function Noti() {
  return (
    <div className="notiContainer">
      <div className="notiChild">Notification not available now!</div>
    </div>
  );
}
export default Noti;
